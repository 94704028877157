// @mixin flex-full {
//   display: flex;
//   flex-direction: column;
//   flex: 1 1 100%;
// }

// @mixin flex-resize {
//   flex-shrink: 1;
//   min-width: 0;
//   min-height: 0;
//   overflow: hidden;
// }

@mixin flex-min {
  min-width: 0;
  min-height: 0;
}

.mobile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100vh;
  overflow: hidden;
  @include flex-min;
}

.mobile-header {
  flex: 0 0 auto;
}

.mobile-board {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 5px 0 5px 0;
  @include flex-min;
}

.mobile-footer {
  flex: 0 0 auto;
}

.mobile-board-paper {
   display: flex;
   flex-direction: column;
   flex: 1 1 100%;
   @include flex-min;
 
}

.mobile-board-canvas {
   display: flex;
   flex: 1 1 100%;
   @include flex-min;
}
