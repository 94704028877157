
.board-panes-content {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 1 100%;
}

.board-left-panes {
  margin: 5px 0px 5px 5px;
  min-width: 230px;
  width: 230px;
}

.board-paper {
  margin: 5px;
  min-width: 800px;
  width: 800px;
  min-height: 800px;
  height: 800px;
}

.architecture {
  padding: 15px 5px 0 5px;
  text-align: center;
  margin-bottom: 5px;
}

.status {
  padding: 15px 5px 0 5px;
  text-align: center;
  height: 135px;
  margin-bottom: 5px;
}

.settings {
  padding: 15px 5px 0 5px;
  text-align: center;
  height: 485px;
  margin-bottom: 5px;
}

.rooms {
  display: none;
  text-align: center;
  height: 250px;
}

.footer {
  text-align: center;
}


.board_loading {
  background: red;
}
