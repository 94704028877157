@mixin mui-paper-status-connection {
  height: 5px;
  width: 100%; 
}

#mui-paper-status-connection-unknown {
  @include mui-paper-status-connection;
  background-color: Sienna;
}
#mui-paper-status-connection-off {
  @include mui-paper-status-connection;
  background-color: FireBrick;
}
#mui-paper-status-connection-on {
  @include mui-paper-status-connection;
  background-color: Lime;
}
