body {
  align-items: center;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  min-width: 100%;
  min-width: 100vw;
  min-height: 100%;
  min-height: 100vh;
  overflow: auto;
}

// html,
// body,
// #root{
//   height: 100%;
//   margin: 0;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  border-top: 1px solid rgb(56, 52, 40);
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.5;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
